import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Divider,
  Drawer,
  Button,
  Collapse,
  List,
  ListItem,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Menu, ArrowLeft, Close } from '@mui/icons-material';
import { PlusCircle as PlusCircleIcon } from 'react-feather';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { THEMES } from 'src/constants';
import { openRecentCases, clearCase } from 'src/actions/caseActions';
import { openCaseNotesList } from 'src/actions/notesActions';
import { openCaseTasksList } from 'src/actions/caseTasksActions';
import { openChecklist } from 'src/actions/checklistActions';
import { AUDIT_READ_USERS } from 'src/services/auditService';
import { Permission } from 'src/components';
import { useGetReferralsCountQuery } from 'src/store/serverState';
import Account from '../TopBar/Account';
import NavItem from './NavItem';
import LinkButton from '../TopBar/LinkButton';
import RecentCases from '../SecondaryTopBar/RecentCases';

const useStyles = makeStyles((theme) => ({
  listStyle: {
    ...(theme.name === THEMES.LIGHT
      ? {
          boxShadow: 'none',
          backgroundColor: theme.palette.primary.main,
          filter: 'brightness(.85)',
        }
      : {}),
    ...(theme.name === THEMES.ONE_DARK
      ? {
          backgroundColor: theme.palette.background.default,
        }
      : {}),
    color: 'white',
    paddingTop: '65px', // in order to sit below topbar
  },

  hamburgerBtn: {
    color: 'white',
  },
  listLinks: {
    display: 'flex',
    justifyContent: 'space-evenly',
    height: 'fit-content',
  },
  active: {
    filter: 'brightness(.75)',
  },
  buttonStyling: {
    height: '30px',
    padding: '10px 8px',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%',
    color: theme.palette.primary.contrastText,
    justifyContent: 'start',
    fontSize: '16px',
    fontWeight: theme.typography.fontWeightRegular,
  },
  title: {
    width: 'initial',
  },
}));

const INIT_NESTED = {
  caseOpen: false,
  recoveryOpen: false,
  medReviewOpen: false,
  approvalsOpen: false,
  transmitOpen: false,
  dashboardOpen: false,
};
function MobileDrawer({ drawer, setDrawer, nestedMenus, setNestedMenus }) {
  const { user } = useSelector((state) => state.account);
  const classes = useStyles();
  const referralCount = useGetReferralsCountQuery();
  const dispatch = useDispatch();
  const caseItem = useSelector((state) => state.caseItem.caseItem);
  const additionalUrl = caseItem.id ? `/${caseItem.id}` : '';

  const closeNested = (state) => {
    setNestedMenus(() => ({ ...INIT_NESTED, [state]: true }));
  };

  // if on page with no case item, close all hamburger tabs (setDrawer)
  useEffect(() => {
    if (!caseItem?.id) {
      setNestedMenus({ INIT_NESTED });
    }
  }, [caseItem]);

  const toggleDrawer = () => {
    setDrawer(!drawer);
  };

  const navItems = [
    {
      title: 'Referral',
      href: 'case',
      disabled: !caseItem.id,
      clickHandler: () =>
        setNestedMenus((menus) => ({ ...menus, caseOpen: !menus.caseOpen })),
      childClickHandler: () => {
        closeNested('caseOpen');
        toggleDrawer();
      },
      children: [
        {
          title: 'Summary',
          href: `/case/${additionalUrl}/referral/summary`,
          permission: 'read:referralSummary',
          disabled: !caseItem.id,
        },
        {
          title: 'Worksheet',
          href: `/case/${additionalUrl}/referral/worksheet`,
          permission: 'read:referralWorksheet',
          disabled: !caseItem.id,
        },
        {
          title: 'Preliminary Donor Screening',
          href: `/case/${additionalUrl}/referral/screening`,
          permission: 'read:referralDonorScreening',
          disabled: !caseItem.id,
        },
        {
          title: 'Preliminary Hemodilution',
          href: `/case/${additionalUrl}/referral/preliminaryHemodilution`,
          permission: 'read:referralPreliminaryHemodilution',
          disabled: !caseItem.id,
        },
        {
          title: 'Contacts',
          href: `/case/${additionalUrl}/referral/contacts`,
          permission: 'read:referralContact',
          disabled: !caseItem.id,
        },
        {
          title: 'Donation Discussion',
          href: `/case/${additionalUrl}/referral/discussion`,
          permission: 'read:referralDonationDiscussion',
          disabled: !caseItem.id,
        },
        {
          title: 'Authorizations',
          href: `/case/${additionalUrl}/referral/authorizations`,
          permission: 'read:authForm',
          disabled: !caseItem.id,
        },
        {
          title: 'Authorized Usage',
          href: `/case/${additionalUrl}/referral/usage`,
          permission: 'read:referralDonationDiscussion',
          disabled: !caseItem.id,
        },
      ],
      openState: nestedMenus.caseOpen,
    },
    {
      title: 'Med Social',
      href: `/case/${additionalUrl}/medSocial`,
      clickHandler: () => {
        closeNested();
        toggleDrawer();
      },
      disabled: !caseItem.id,
      renderAsNavItem: true,
    },
    {
      title: 'Recovery',
      href: 'recovery',
      clickHandler: () =>
        setNestedMenus((menus) => ({
          ...menus,
          recoveryOpen: !menus.recoveryOpen,
        })),

      childClickHandler: () => {
        closeNested('recoveryOpen');
        toggleDrawer();
      },
      disabled: !caseItem.id,
      children: [
        {
          title: 'Recovery Supplies',
          href: `/case/${additionalUrl}/recovery/supplies`,
          permission: 'read:recoveryTissue',
          disabled: false,
        },
        {
          title: 'Donor Information',
          href: `/case/${additionalUrl}/recovery/donorInformation`,
          permission: 'read:recoveryDonorInformation',
          disabled: false,
        },
        {
          title: 'Recovery Site',
          href: `/case/${additionalUrl}/recovery/recoverySite`,
          permission: 'read:recoverySite',
          disabled: false,
        },
        {
          title: 'Physical Assessment',
          href: `/case/${additionalUrl}/recovery/physicalAssessment`,
          permission: 'read:recoveryPhysicalAssessment',
          disabled: false,
        },
        {
          title: 'Eye Assessment',
          href: `/case/${additionalUrl}/recovery/eyeAssessment`,
          permission: 'read:recoveryEyeAssessment',
          disabled: false,
        },
        {
          title: 'Tissue Recovery',
          href: `/case/${additionalUrl}/recovery/tissue`,
          permission: 'read:recoveryTissue',
          disabled: false,
        },
        {
          title: 'Shipping Details',
          href: `/case/${additionalUrl}/recovery/shippingDetails`,
          permission: 'read:recoveryShippingDetail',
          disabled: false,
        },
        {
          title: 'Serologies',
          href: `/case/${additionalUrl}/recovery/serologies`,
          permission: 'read:recoverySerology',
          disabled: false,
        },
      ],
      openState: nestedMenus.recoveryOpen,
    },
    {
      title: 'Medical Review',
      href: 'medicalReview',
      clickHandler: () =>
        setNestedMenus((menus) => ({
          ...menus,
          medReviewOpen: !menus.medReviewOpen,
        })),

      childClickHandler: () => {
        closeNested('medReviewOpen');
        toggleDrawer();
      },

      disabled: !caseItem.id,
      children: [
        {
          title: 'Summary',
          href: `/case/${additionalUrl}/medicalReview/summary`,
          permission: 'read:medicalReviewSummary',
          disabled: false,
        },
        {
          title: 'Pathology',
          href: `/case/${additionalUrl}/medicalReview/pathology`,
          permission: 'read:medicalReviewPathology',
          disabled: false,
        },
        {
          title: 'CBC/Temp/Meds',
          href: `/case/${additionalUrl}/medicalReview/cbcTempMeds`,
          permission: 'read:medicalReviewCbcTempMeds',
          disabled: false,
        },
        {
          title: 'Culture Results',
          href: `/case/${additionalUrl}/medicalReview/cultureResults`,
          permission: 'read:medicalReviewCultureResult',
          disabled: false,
        },
        {
          title: 'Hemodilution',
          href: `/case/${additionalUrl}/medicalReview/hemodilution`,
          permission: 'read:medicalReviewHemodilution',
          disabled: !caseItem.id,
        },
        {
          title: 'Serologies',
          href: `/case/${additionalUrl}/medicalReview/serologies`,
          permission: 'read:medicalReviewSerology',
          disabled: !caseItem?.id,
        },
      ],
      openState: nestedMenus.medReviewOpen,
    },

    {
      title: 'Approvals',
      href: 'approvals',
      clickHandler: () =>
        setNestedMenus((menus) => ({
          ...menus,
          approvalsOpen: !menus.approvalsOpen,
        })),
      childClickHandler: () => {
        closeNested('approvalsOpen');
        toggleDrawer();
      },
      disabled: !caseItem.id,
      children: [
        {
          title: 'Approval',
          href: `/case/${additionalUrl}/approvals/approval`,
          permission: 'read:approval',
          disabled: false,
        },
        {
          title: 'Disposition',
          href: `/case/${additionalUrl}/approvals/disposition`,
          permission: 'read:disposition',
          disabled: false,
        },
        {
          title: 'Case Lock',
          href: `/case/${additionalUrl}/approvals/lock`,
          permission: 'read:caseLock',
          disabled: false,
        },
      ],
      openState: nestedMenus.approvalsOpen,
    },
    {
      title: 'Transmit',
      href: 'transmit',
      clickHandler: () =>
        setNestedMenus((menus) => ({
          ...menus,
          transmitOpen: !menus.transmitOpen,
        })),
      childClickHandler: () => {
        closeNested('transmitOpen');
        toggleDrawer();
      },
      disabled: !caseItem.id,
      children: [
        {
          title: 'Transmit Log',
          href: `/case/${additionalUrl}/transmit/log`,
          permission: 'read:transmitLog',
          disabled: !caseItem.id,
        },
        {
          title: 'Transmit Data',
          href: `/case/${additionalUrl}/transmit/data`,
          permission: 'read:transmit',
          disabled: !caseItem.id,
        },
      ],
      openState: nestedMenus.transmitOpen,
    },
    {
      title: 'Attachments',
      href: `/case/${additionalUrl}/attachments`,
      permission: 'read:attachments',
      clickHandler: () => {
        toggleDrawer();
        closeNested();
      },
      disabled: !caseItem.id,
      renderAsNavItem: true,
    },
    {
      title: 'Audit',
      permission: AUDIT_READ_USERS,
      href: `/case/${additionalUrl}/audit`,
      clickHandler: () => {
        toggleDrawer();
        closeNested();
      },
      disabled: !caseItem.id,
      renderAsNavItem: true,
    },
    {
      title: 'Case Notes',
      href: 'notes',
      permission: 'read:notes',
      clickHandler: () => {
        dispatch(openCaseNotesList());
        toggleDrawer();
      },
      disabled: !caseItem.id,
    },
    {
      title: 'Case Tasks',
      href: 'tasks',
      permission: 'read:tasks',
      clickHandler: () => {
        dispatch(openCaseTasksList());
        toggleDrawer();
      },
      disabled: !caseItem.id,
    },
    {
      title: 'Checklist',
      href: 'checklist',
      permission: 'read:caseChecklist',
      clickHandler: () => {
        dispatch(openChecklist());
        toggleDrawer();
      },
      disabled: !caseItem.id,
    },
    {
      title: 'Reports',
      href: '/reports',
      clickHandler: () => {
        toggleDrawer();
        closeNested();
      },
      disabled: false,
      renderAsNavItem: true,
    },
    {
      title: 'Dashboards',
      href: 'dashboards',
      clickHandler: () =>
        setNestedMenus((menus) => ({
          ...menus,
          dashboardOpen: !menus.dashboardOpen,
        })),
      childClickHandler: () => {
        closeNested();
        toggleDrawer();
      },
      disabled: false,
      children: [
        {
          title: `Active Cases(${referralCount.data?.casesCount || 0})`,
          href: `/dashboards/activeCases`,
          permission: 'read:dashboardActiveCases',
          disabled: false,
        },
        {
          title: `Active Referral(${referralCount.data?.referralCount || 0})`,
          href: `/dashboards/activeReferral`,
          disabled: false,
        },
      ],
      openState: nestedMenus.dashboardOpen,
    },
    {
      title: 'Tasks',
      href: 'tasks',
      clickHandler: () => {
        toggleDrawer();
        closeNested();
      },
      disabled: false,
      renderAsNavItem: true,
    },
    {
      title: 'Inbox',
      href: '/inbox',
      clickHandler: () => {
        toggleDrawer();
        closeNested();
      },
      disabled: false,
      renderAsNavItem: true,
    },
    {
      title: 'Recent Cases',
      clickHandler: (event) => dispatch(openRecentCases(event)),
      disabled: false,
    },
  ];

  const mapLinks = navItems
    ?.filter(
      (item) => !item.permission || user.permissions?.includes(item.permission),
    )
    .map((item) => {
      return (
        <div key={item.title}>
          <Divider />
          {item.renderAsNavItem ? (
            <NavItem
              onClick={item.disabled ? null : item.clickHandler}
              href={item.href}
              key={item.title}
              title={item.title}
              disabledProp={item.disabled}
              topLevel
            />
          ) : (
            <ListItem disablePadding>
              <Button
                onClick={item.clickHandler}
                disabled={item.disabled}
                className={classes.buttonStyling}
              >
                <span className={classes.title}>{item.title}</span>
              </Button>
              {item.children &&
                (item.openState ? <ArrowDropDownIcon /> : <ArrowLeft />)}
            </ListItem>
          )}
          {item.children && (
            <Collapse
              in={item.openState}
              timeout="auto"
              unmountOnExit
            >
              <List
                component="div"
                disablePadding
              >
                {item.children
                  .filter(
                    (innerItem) =>
                      !innerItem.permission ||
                      user.permissions?.includes(innerItem.permission),
                  )
                  .map((innerItem) => {
                    return (
                      <NavItem
                        onClick={
                          innerItem.disabled ? null : item.childClickHandler
                        }
                        href={innerItem.href}
                        isOpen={item.openState}
                        key={innerItem.title}
                        title={innerItem.title}
                        disabledProp={innerItem.disabled}
                      />
                    );
                  })}
              </List>
            </Collapse>
          )}
        </div>
      );
    });

  return (
    <>
      <Button
        onClick={() => {
          toggleDrawer();
        }}
        className={classes.hamburgerBtn}
      >
        {drawer ? <Close /> : <Menu />}
      </Button>
      <RecentCases />
      <Drawer
        elevation={12}
        className={classes.root}
        anchor="top"
        open={drawer}
        onClose={toggleDrawer}
      >
        <List
          className={classes.listStyle}
          disablePadding
        >
          <ListItem
            disablePadding
            className={classes.listLinks}
          >
            <Account />
            <Permission permit="create:referralWorksheet">
              <LinkButton
                to="/case/worksheet"
                onClick={() => {
                  dispatch(clearCase());
                  toggleDrawer();
                }}
                icon={<PlusCircleIcon />}
                fontSize="large"
                toolTip="New Case"
              />
            </Permission>
          </ListItem>
          {mapLinks}
        </List>
      </Drawer>
    </>
  );
}

export default MobileDrawer;
